import React from 'react'
import { Container, Row } from 'styled-bootstrap-grid2'
import Container3Col from '../../components/container_3col'
import ProductListManualCollection from '../../components/product_list_manual_collection'
import SEO from '../../components/seo'

const Shop = () => (
  <div>
  <SEO title="Shop - Earrings" keywords={[`Shop`, `Jewellery`, `Theodora Warre`]} />
    <Container3Col longpage top>
      <Container>
        <Row>
          <ProductListManualCollection category="all" />
        </Row>
      </Container>
    </Container3Col>
  </div>
)

export default Shop
